<template>
  <div class="app-download">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center"></div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="menu first" @click="onGotoAppDownload('android')">
        <div class="item">
          <div class="text">
            <!-- <a href="http://9gpf2.ios0.vip/downpage/8517a07535c64bc6">安卓下载</a> -->
            安卓安装方式，无需下载
          </div>
        </div>
      </div>
      <div class="menu last" @click="onGotoAppDownload('ios')">
        <div class="item">
          <div class="text">
            iOS安装方式，无需下载
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <footerMenu></footerMenu>
    </div>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
export default {
  components: {
    footerMenu,
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
    };
  },
  methods: {
    onGoBack() {
      this.$router.go(-1);
    },
    onGotoAppDownload(mobileType) {
      this.$router.push({
        name: "addToScreenStep",
        query: {
          type: mobileType,
        },
      });
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>
.app-download {
  display: flex;
  flex-direction: column;
  background: rgb(241, 241, 241);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    align-items: center;
    .menu {
      height: 70px;
      width: 380px;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .item {
        height: 68px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(240, 240, 240);
        width: 360px;
        .text {
          font-size: 16px;
          color: #323233;
          letter-spacing: 0.44px;
          font-weight: 600;
          margin: 0 0 0 21px;
          a {
            text-decoration: none;
            color: #323233;
          }
        }
      }
      .step {
        display: flex;
        flex-direction: column;
        .img {
          width: 80%;
          height: 80%;
          display: block;
        }
      }
    }
    .first {
      border-radius: 16px 16px 0 0;
    }
    .last {
      border-radius: 0 0 16px 16px;
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
